$primary-color:#393E46;
$primary-button:#0067b8;
$secondary-button:#393E46;

//Breakpoints
$extrasmall:320px;
$small:576px;
$medium:768px;
$large:992px;
$extralarge:1440px;

:root{
    .primary-button{
        color: #fff;
        background-color:$primary-button;
    }

    .secondary-button{
        color: #fff;
        background-color:$primary-button;
        opacity: 0.7;
    }
}

@mixin extrasmall {
    @media (max-width: $extrasmall) { @content }
};

@mixin small {
    @media (max-width: $small) { @content }
};

@mixin medium {
    @media (max-width: $medium) { @content }
};

@mixin large {
    @media (max-width: $large) { @content }
};

@mixin extralarge {
    @media (max-width: $extralarge) { @content }
};