@import './../../../variables.scss';

.grid-item{
    margin: 25px;
}

#weekplanNameSection{
    margin: 5px 10px 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include small{
        margin-top: 50px;
    }
}

#weekplanCreateButtons{
    display: flex;
    align-self: center;

    @include small{
        margin-top: 50px;
    }
}

.create-weekplan-label{
    svg{
        vertical-align: sub;
        margin-right: 10px;
    }
}

.suggestions-drawer{
    >div{
       padding: 10px;
       padding-top: 50px;
    }
}

#closeSuggestions{
    position: absolute;
    top: 10px;
}

#createContainer{
    display: flex;
    flex-direction: row;
    margin: 0 10% 0 10%;
    @include small{
        flex-direction: column;
        margin: 0;
    }

    >div{
        position: relative;
        flex: 1;
        flex-direction: column;
        margin: 20px;
    }

    .icon-section{
        position: absolute;
        top:10px;
        right: 10px;

        span{
            cursor: pointer;
        }
    }
}

.menuitem-delete{
    position: absolute;
    top: 10px;
    right: 10px;
}




