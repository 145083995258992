@import './../../../variables.scss';

#weekplansNavbar{
    text-align: center;
}

.shopping-grid{
    position: relative;
    // overflow-y: auto;
}

#weekplanButtons{

    @include small{
        display: block;
        margin-top: 15px;
    }
}

.not-selected-message{
    position: absolute;
    top: 50%;
}

#fullscreenButton{
    position: absolute;
    top: 15px;
    right: 35px;
    cursor: pointer;
    font-size: 20px;
    display: none;

    @include small{
        display: block;
    }
}


#weekplanContainer{
    display: flex;
    flex-direction: row;
    margin: 0 10% 0 10%;
    margin-top: 50px;
   
    >div{
        flex: 1;
        flex-direction: column;
    }

    @include small{
        flex-direction: column;
        margin: 0 !important;
    }
}
