.about-section {
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;

   

    >div {
        padding: 20px;
    }

    .center {
        text-align: center; 
    }

    a {
        // text-decoration: none;
        color: black;
    }
}

.about-title{
    div::first-letter{
        display: block;
        font-size: 40px;
        color: red;
    }
}