@import './variables.scss';



.navbar-link {
  text-decoration: none;
  color: white;
  font-size: 22px;
  margin: 30px;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  width: 120px;
  justify-content: center;
  transition: 0.3s all;

  svg {
    vertical-align: middle;
    margin-right: 5px;
  }

  &::after {
    content: "";
    width: 20px;
    height: 4px;
    z-index: 200;
    position: absolute;
    background-color: #d8e3ed;
    bottom: 0;
    width: 100%;
    opacity: 0;
    width: 0;
    transition: 0.3s all;
    border-radius: 2px;

  }

  &.active::after {
    opacity: 1;
    width: 100%;
    transition: 0.3s all;
  }

  @include small{
    display: none;
  }
}

.navbar-link:active {
  color: black;
}

.main-navbar {
  width: 100%;
  height: 80px;
  background-color: $primary-color;
  position: absolute;
  top: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  .navbar-login {
    position: absolute;
    left: 20px;
    display: flex;
    width: 40px;
    cursor: pointer;
    overflow: hidden;
  }

}

#notLoggedIn {
  margin-top: 100px;
}

.app-content {
  margin: 150px 60px 100px 60px;

  @include small {
    margin: 100px 0 0 0 !important;
  }
}

.drawer-close {
  cursor: pointer;
}

#closeUserMenu {
  position: absolute;
  right: 10px;
}

#logo{
  position: absolute;
  right: 30px;
  cursor: pointer;

  img{
    display: block;
    margin: 0 auto;
  }

  div{
    color:white;
    font-weight: bold;
    font-size: 100%;
  }

  @include small{
    display: none;
  }
}

.hidden{
  display: none !important;
}

.paper-wrapper{
  padding:20px 40px 40px 40px;
  overflow-y: auto;
}

#mobileMenu{
  display: none;
  @include small{
    display: block;
  }
}

#navbarMobileMenu{
  display: none;
  position: absolute;
  right: 15px;

  >span{
    font-weight: bold;
    color:white;
  }

  svg{
    color:white
  }

  @include small{
    display: block;
  }
}

@media print {

  .paper-wrapper{
    padding-top:5px !important;
  }

  #weekplanContainer{
      margin: 0 !important;
  }
  .shoppinglist-container {
    >div {
      margin-top: 5px;
    }

    input {
      border-bottom: 0 !important;
    }

  }

  .no-print {
    display: none !important;
  }

  .print-only {
    display: initial;
  }

  .app-content {
    margin: 10px !important;
  }

  .grid-item {
    margin:5px !important;
  }

}